import { render, staticRenderFns } from "./liveBroadcast.vue?vue&type=template&id=dabe0c42&scoped=true&"
import script from "./liveBroadcast.vue?vue&type=script&lang=js&"
export * from "./liveBroadcast.vue?vue&type=script&lang=js&"
import style0 from "./liveBroadcast.vue?vue&type=style&index=0&id=dabe0c42&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "dabe0c42",
  null
  
)

export default component.exports