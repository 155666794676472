<template>
	<div>
		<div class="toper">
			<div class="top" style="display: flex;align-items: center;justify-content: space-between;">
				<div @click="back">
					<img src="../../assets/image/login/back.png" style="width: 11px;height: 18px;">
				</div>
				<div style="color: #434444;font-size: 16px;font-weight: bold;">直播列表</div>
				<div></div>
			</div>
			<img :src="host+bannerList[0].banner_url" class="images">
		</div>
		<div class="content">
			<div class="contentChild" v-for="(item,index) in list" :key='index'>
				<div style="display: flex;align-items: center;">
					<img :src="host+item.logo" style="width: 50px;height: 50px;">
					<div style="margin-left: 12px;">
						<div style="color: #434444;font-size: 14px;font-weight: bold;">{{item.name}}</div>
						<div style="color: #999999;font-size: 12px;margin-top: 5px;">{{item.description}}</div>
					</div>
				</div>
				<div>
					<div class="zbBtn" @click="goUrl(item)">进入直播</div>
					<div class="zbBtn" @click="goUrl(item)" style="background: #86838C;color: #fff;margin-top: 4px;">
						直播回放</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	import {
		host
	} from '../../utils/host.js'
	import {
		liveBroadcast,
		liveBanner
	} from '../../api/home.js'
	export default {
		name: "liveBroadcast",
		data() {
			return {
				host: host,
				list: [],
				bannerList: [],
			}
		},
		components: {

		},
		created() {
			this.getList()
			this.getBanner()
		},
		methods: {
			goUrl(item) {
				window.location.href = item.live_broadcast
			},
			getList() {
				liveBroadcast().then(res => {
					console.log('直播列表', res)
					this.list = res.data
				})
			},
			getBanner() {
				liveBanner().then(res => {
					console.log('获取banner', res)
					this.bannerList = res.data
					console.log(this.bannerList)
				})
			},
			back() {
				this.$router.go(-1)
			}
		}
	}
</script>

<style scoped>
	.toper {
		background: #DD1C25;
		height: 290px;
		padding-top: 20px;
		box-sizing: border-box;
		text-align: center;
	}

	.top {
		padding: 0 16px;
		box-sizing: border-box;
	}

	.images {
		margin: 13px 16px 0 16px;
		height: 224px;
		border-radius: 8px;
	}

	.content {
		margin: 14px 16px 0 16px;
		padding: 10px 14px 0 14px;
		box-sizing: border-box;
		background: #FFFFFF;
		box-shadow: 0px 2px 8px 0px rgba(192, 191, 191, 0.5);
		border-radius: 8px;
	}

	.contentChild {
		padding: 10px 0;
		box-sizing: border-box;
		border-bottom: 1px solid #E7E6E6;
		display: flex;
		align-items: center;
		justify-content: space-between;
	}

	.zbBtn {
		width: 70px;
		height: 23px;
		background: #FFE5E7;
		border-radius: 12px;
		text-align: center;
		line-height: 23px;
		color: #DD1C25;
		font-size: 12px;
	}
</style>
